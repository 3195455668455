/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import idx from 'idx'
import mapValues from 'lodash/mapValues'
import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'
import kebabCase from 'lodash/kebabCase'
import { PrismicRichText } from '@prismicio/react'

import { theme } from '../../../styles'
import gray500CheckIcon from '../../../../static/icons/check-icons/gray500-check-icon.svg'
import xIcon from '../../../../static/icons/misc-icons/x-icon.svg'
import grayArrowIcon from '../../../../static/icons/arrow-icons/gray-left-arrow-icon.svg'
import Text from '../../common/Text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 80px 0px;
  max-width: 100vw;

  @media (max-width: 1500px) {
    padding: 80px 24px;
  }

  @media (max-width: 1050px) {
    width: calc(100% + 20px);
    max-width: unset;
    margin-left: 0px;
    overflow-x: scroll;
    align-items: flex-start;
    padding: 80px 24px 0px 0px;
    margin-bottom: 80px;
  }
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1050px) {
    overflow-x: scroll;
    width: max-content;
  }
`

const ColumnHeader = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  border: 2px solid ${p => p.theme.color[p.borderColor]};
  width: 100%;
  padding: 16px 0px;
  border-radius: 8px;
`

const RowGroup = styled.div``

const Row = styled.div`
  display: flex;
  gap: 16px;
  padding-left: 16px;
  justify-content: flex-start;
  border: 1px solid ${theme.color.gray200};
  border-bottom: none;
  background-color: ${p => p.theme.color[p.backgroundColor]};

  &:nth-of-type(2) {
    border-radius: 16px 16px 0px 0px;
  }

  &:last-of-type {
    border-bottom: 1px solid ${theme.color.gray200};
    border-radius: 0px 0px 16px 16px;
  }
`

const HeadingRow = styled(Row)`
  margin-bottom: 24px;
  border-color: transparent;
  padding-left: 0px;

  &:last-of-type {
    border-bottom: none;
  }
`

const SectionHeadingRow = styled(HeadingRow)`
  margin-bottom: 0px;
`

const TableCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Block = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 16px 0px;

  @media (max-width: 1050px) {
    width: 50vw;
    max-width: 300px;
  }

  @media (max-width: 800px) {
    max-width: 225px;
    width: 80vw;
  }
`

const HeadingBlock = styled.div`
  display: flex;
  flex: 1.5;
  align-items: center;
  padding-right: 16px;

  @media (max-width: 1050px) {
    display: none;
  }
`

const MobHeadingBlock = styled.div`
  display: none;

  @media (max-width: 1050px) {
    display: flex;
    margin-bottom: 24px;
  }
`

const FeatureTextBlock = styled(Block)`
  flex: 1.5;
  justify-content: flex-start;

  p {
    margin-bottom: 0px !important;
  }

  @media (max-width: 1050px) {
    flex: 1;
  }
`

const ArrowIcon = styled.img`
  margin-left: 16px;
`

const MobileSwipeText = styled(Text)`
  display: none;

  @media (max-width: 1050px) {
    display: flex;
    padding-right: 16px;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`

const BlockText = ({ text, fontFamily, ...rest }) => (
  <FeatureTextBlock {...rest}>
    <Text richText font="smallBodyMedium" color="gray800">
      {text}
    </Text>
  </FeatureTextBlock>
)

const Section = ({ title, sections, lastSection }) => (
  <>
    {sections[title].map((item, i) => {
      const { feature_text } = item

      return (
        // eslint-disable-next-line react/no-array-index-key
        <Row role="row" key={`${title}-${i}`} backgroundColor={i % 2 ? 'white' : 'gray50'}>
          <BlockText
            text={<PrismicRichText field={feature_text.richText} />}
            role="cell"
            aria-labelledby={`table-header-${kebabCase(title)}`}
          />
          <>
            <Block role="cell" aria-labelledby={`table-header-${kebabCase(title)}`}>
              {!item.with_automated && !item.automated_column_text ? (
                <img src={xIcon} alt={`${idx(feature_text, __ => __.text)} is not included with automated`} />
              ) : null}
              {item.with_automated ? (
                <img
                  src={gray500CheckIcon}
                  alt={`${idx(feature_text, __ => __.text)} is included with automated`}
                  style={{ width: '18px' }}
                />
              ) : (
                <Text smallBodyMedium color="gray500">
                  {idx(item.automated_column_text, __ => __.text)}
                </Text>
              )}
            </Block>
            <Block role="cell" aria-labelledby={`table-header-${kebabCase(title)}`}>
              {!item.with_self_managed && !item.self_managed_column_text ? (
                <img src={xIcon} alt={`${idx(feature_text, __ => __.text)} is not included with self managed`} />
              ) : null}
              {item.with_self_managed ? (
                <img
                  src={gray500CheckIcon}
                  alt={`${idx(feature_text, __ => __.text)} is included with self managed`}
                  style={{ width: '18px' }}
                />
              ) : (
                <Text smallBodyMedium color="gray500">
                  {idx(item.self_managed_column_text, __ => __.text)}
                </Text>
              )}
            </Block>
            <Block role="cell" aria-labelledby={`table-header-${kebabCase(title)}`}>
              {!item.with_managed && !item.managed_column_text ? (
                <img src={xIcon} alt={`${idx(feature_text, __ => __.text)} is not included with managed`} />
              ) : null}
              {item.with_managed ? (
                <img
                  src={gray500CheckIcon}
                  alt={`${idx(feature_text, __ => __.text)} is included with managed`}
                  style={{ width: '18px' }}
                />
              ) : (
                <Text smallBodyMedium color="gray500">
                  {idx(item.managed_column_text, __ => __.text)}
                </Text>
              )}
            </Block>
          </>
        </Row>
      )
    })}
  </>
)

const FeaturesTable = ({ slice: { primary, items } }) => {
  const anchorId = primary.anchor_id
  const title = primary.heading.richText?.[0]?.text && <PrismicRichText field={primary.heading.richText} />
  const automatedColumnHeading = primary.automated_column_heading
  const selfManagedColumnHeading = primary.self_managed_column_heading
  const managedColumnHeading = primary.managed_column_heading

  const sections =
    items &&
    items[0] &&
    mapValues(
      groupBy(items, val => (idx(val, idxArg => idxArg.feature_section.text) ? val.feature_section.text : 'Features')),
      clist => clist.map(car => omit(car, 'feature_section'))
    )

  return (
    <Wrapper id={anchorId || null}>
      <TableWrapper role="table">
        <MobHeadingBlock>
          <Text font="heading3" richText>
            {title}
          </Text>
        </MobHeadingBlock>
        <div role="rowgroup" style={{ zIndex: '1' }}>
          <HeadingRow role="row">
            <HeadingBlock role="cell">
              <Text font="heading3" richText>
                {title}
              </Text>
            </HeadingBlock>
            <MobileSwipeText smallBody color="gray700">
              <ArrowIcon src={grayArrowIcon} style={{ marginRight: '16px' }} />
              Swipe to see and compare plans
            </MobileSwipeText>
            <ColumnHeader borderColor="gray200">
              <Text bodyBold center role="columnheader">
                {automatedColumnHeading.text}
              </Text>
            </ColumnHeader>
            <ColumnHeader borderColor="purple200">
              <Text bodyBold center role="columnheader">
                {selfManagedColumnHeading.text}
              </Text>
            </ColumnHeader>
            <ColumnHeader borderColor="purple400">
              <Text bodyBold center role="columnheader">
                {managedColumnHeading.text}
              </Text>
            </ColumnHeader>
          </HeadingRow>
        </div>
        <TableCard>
          {Object.keys(sections).map((key, i) => (
            <RowGroup role="rowgroup" key={key}>
              <SectionHeadingRow role="row">
                <Text
                  color="gray600"
                  heading4
                  role="cell"
                  style={{ flex: '1', padding: '24px 0px' }}
                  id={`table-header-${kebabCase(key)}`}
                >
                  {key}
                </Text>
              </SectionHeadingRow>
              <Section title={key} sections={sections} lastSection={i === Object.keys(sections).length - 1} />
            </RowGroup>
          ))}
        </TableCard>
      </TableWrapper>
    </Wrapper>
  )
}

export default FeaturesTable

export const query = graphql`
  fragment FeaturesTable on PrismicPageDataBodyFeaturesTable {
    ...SliceBase
    primary {
      anchor_id
      heading {
        ...TextContent
      }
      automated_column_heading {
        ...TextContent
      }
      self_managed_column_heading {
        ...TextContent
      }
      managed_column_heading {
        ...TextContent
      }
      complete_column_heading {
        ...TextContent
      }
    }
    items {
      feature_section {
        ...TextContent
      }
      feature_text {
        ...TextContent
      }
      full_feature_list_only
      automated_column_text {
        ...TextContent
      }
      self_managed_column_text {
        ...TextContent
      }
      managed_column_text {
        ...TextContent
      }
      complete_column_text {
        ...TextContent
      }
      with_automated
      with_self_managed
      with_managed
      with_complete
    }
  }
`
